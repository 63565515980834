<template>
  <div>
    <div class="cover story-steep">
      <div class="story-images">
        <img
          v-rellax="rellax"
          data-rellax-speed="0"
          data-rellax-xs-speed="0"
          class="img-portada img-portada-1"
          src="../assets/img/home/parallax/Fondo.png"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="-7"
          data-rellax-xs-speed="-7"
          class="img-portada img-portada-2"
          src="../assets/img/home/parallax/costillas.svg"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="-4"
          data-rellax-xs-speed="-4"
          class="img-portada img-portada-3"
          src="../assets/img/home/parallax/pulmones.svg"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="-5"
          data-rellax-xs-speed="-5"
          class="img-portada img-portada-4"
          src="../assets/img/home/parallax/zoomizq.svg"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="-5"
          data-rellax-xs-speed="-5"
          class="img-portada img-portada-5"
          src="../assets/img/home/parallax/zoomderecho.svg"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="-8"
          data-rellax-xs-speed="-8"
          class="img-portada img-portada-7"
          src="../assets/img/home/parallax/virus.svg"
          alt=""
        />
        <img
          v-rellax="rellax"
          data-rellax-speed="0"
          data-rellax-xs-speed="0"
          class="img-portada img-portada-6"
          src="../assets/img/home/parallax/filtro.png"
          alt=""
        />
      </div>
      <div class="cover-gradient">
        
        <h1 class="cover-title">
          <span class="a11y-contraste-fondo"
            >Conahcyt frente a la
            <span style="white-space: nowrap">Covid-19</span></span
          >
        </h1>
        <h2 class="cover-subtitle">
          <span class="a11y-contraste-fondo"
            >Un capítulo del Ecosistema Nacional Informático de Salud</span
          >
        </h2>
      </div>
    </div>
    
    <div class="container text a11y-simplificada-block">
      <h2 class="titular-portada m-t-3-sm m-t-4-md m--b-1 m-x-auto">
        Conahcyt frente a la <span style="white-space: nowrap">Covid-19</span>
      </h2>
    </div>
    <div class="container text m-t-2 m-t-4-md">
      <p>
        El capítulo Conahcyt frente a la
        <span style="white-space: nowrap">Covid-19</span> del Ecosistema
        Nacional Informático de Salud forma parte del Proyecto Nacional de
        Investigación e Incidencia Covid-19 (Pronaii Covid-19) dentro del
        Programa Nacional Estratégico de Salud.
      </p>
      <p>
        En este capítulo se han articulado capacidades y herramientas en ciencia
        de datos, tecnologías inteligentes y cómputo en los siguientes ejes:
      </p>
      <ol>
        <li>Repositorio de datos y de investigaciones sobre la Covid-19.</li>
        <li>Integración, curaduría y análisis de datos.</li>
        <li>Modelación de la dinámica de la pandemia en México.</li>
        <li>Integración de tableros de información y herramientas.</li>
        <li>
          Análisis espaciales y geográficos, sistemas de información geográfica.
        </li>
      </ol>
      <p>
        Este esfuerzo permite ordenar, estandarizar, procesar, analizar,
        visualizar, proyectar y distribuir la información, proveniente de
        diversas fuentes, relevante para la toma de decisiones sobre la pandemia
        de la enfermedad Covid-19, producida por el virus SARS-CoV-2.
      </p>
      <p>
        En el desarrollo de cada eje, participaron investigadoras e
        investigadores expertos en el tema con el objetivo de lograr, de manera
        conjunta, avances en torno a este reto para comprender, analizar y
        responder mejor a la pandemia de la enfermedad Covid-19 en México. Todo
        ello, en estrecha coordinación y retroalimentación con la Secretaría de
        Salud.
      </p>
      <GridLinkCoverImg class="m-t-1 m-t-2-md m-b-4-md">
        <LinkCoverImg
          :cover_movil="
            require('../assets/img/home/211117_Variantes_302x275_mobil.gif')
          "
          :cover_escritorio="
            require('../assets/img/home/211117_Variantes_688x243_web.gif')
          "
          :url="`${domain}/coronavirus/variantes/`"
          target="_blank"
          title="Vigilancia de variantes del virus SARS-Cov-2"
          size="full"
        />
        <LinkCoverImg
          :cover_movil="require('../assets/img/home/rt_movil.gif')"
          :cover_escritorio="require('../assets/img/home/rt.gif')"
          :url="`${domain}/coronavirus/investigacion/`"
          target="_blank"
          title="Productos de investigación y modelado de datos Covid-19"
          size="full"
        />
        <LinkCoverImg
          :cover="require('../assets/img/home/vacunacion.png')"
          :url="`${domain}/coronavirus/vacunacion/#/`"
          target="_blank"
          title="Plataforma para la Planeación Logística de la Campaña Nacional de Vacunación Covid-19"
          size="half-desk"
        />
        <LinkCoverImg
          :cover="require('../assets/img/home/repositorio.png')"
          url="https://covid-19.conacyt.mx/jspui/"
          target="_blank"
          title="Repositorio Conahcyt Covid-19"
          size="half-desk"
          :degradado_activo="false"
        />
      </GridLinkCoverImg>
    </div>
  </div>
</template>

<script>
import GridLinkCoverImg from "@/components/base/GridLinkCoverImg.vue";
import LinkCoverImg from "@/components/base/LinkCoverImg.vue";
import VueRellax from "vue-rellax";
import Vue from "vue";
Vue.use(VueRellax);

export default {
  name: "Inicio",
  components: {
    GridLinkCoverImg,
    LinkCoverImg,
  },
  data() {
    return {
      domain: process.env.VUE_APP_ENI_DOMAIN,
      rellax: {
        center: false,
        horizontal: false,
        round: true,
        vertical: true,
        wrapper: null,
      },
      base_route: process.env.VUE_APP_BASE_ROUTE,
    };
  },
  computed: {
    obtenerNombreEni() {
      return this.$store.getters.obtenerNombreEni;
    },
  },
};
</script>
<style lang="scss">
.story-steep {
  margin-bottom: 50px;
}
ol li {
  margin-bottom: 0px;
}

.titular-alto {
  line-height: 46px;
  @media (min-width: map-get($media-queries-limit, "mobile")) {
    line-height: 86px;
  }
}
.cover {
  height: 93vh;
  background-image: none;
  overflow: hidden;

  div.story-images {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 93vh;
    @media (min-width: map-get($media-queries-limit, "mobile")) {
      height: 100vh;
    }
    img {
      height: 93vh;
      position: absolute;
      width: 100%;
      max-width: 100%;
      left: 0;
      object-fit: cover;
      object-position: center;
      @media (min-width: map-get($media-queries-limit, "mobile")) {
        height: 100vh;
      }
    }
  }

  @media (min-width: map-get($media-queries-limit, "mobile")) {
    height: 100vh;
  }
}
.cover-gradient {
  z-index: 1;
}
.img-logos {
  margin-top: 200px;
  @media (min-width: map-get($media-queries-limit, "mobile")) {
    width: 400px;
  }
}

.titulo-eni {
  max-width: 750px;
  margin: $gap auto $gap;
  @media (min-width: map-get($media-queries-limit, "mobile")) {
    text-align: center;
    margin: $gap * 1 auto $gap * 1;
  }
}

p.ultimo-parrafo {
  margin-bottom: $gap * 2;
}
</style>
